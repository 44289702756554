import { useEffect, useMemo, useState } from 'react';
import { CircleFlag, getPhoneNumberExample, ISO3166Alpha2 } from '@bits/i18n';
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';

import { ThemedInput, ThemedSearchableSelect } from '.';
import { cn } from '../utils/cn';
import { callCodes } from '../utils/country-call-codes';
import { countryCodeToName } from '../utils/country-code';

const callCodeArray = Object.values(callCodes);

export type ThemedPhoneNumberInputProps = {
  label?: string;
  error?: string;
  hint?: string;
  defaultValue?: string;
  onValueChange?: (v: {
    internationalNumber?: string;
    nationalNumber?: string;
  }) => void;
  className?: string;
  defaultCountryCode?: ISO3166Alpha2;
  language?: string;
  placeholder: string;
  emptyText: string;
};

const getCallCodeForCountry = (countryCode: ISO3166Alpha2) =>
  callCodeArray.find((cc) => cc.countryCode === countryCode);

export const validatePhoneNumber = (phoneNumber: string) =>
  // Only allow -, (, ), +, digits and spaces, then verify the possibility
  /^[0-9\-\(\)\s\+]+$/.test(phoneNumber) && isPossiblePhoneNumber(phoneNumber);

export { formatPhoneNumberIntl };

export const ThemedPhoneNumberInput = ({
  defaultValue,
  defaultCountryCode = 'SE',
  language = 'en',
  onValueChange,
  label,
  error,
  hint,
  emptyText,
  placeholder,
}: ThemedPhoneNumberInputProps) => {
  const defaultNumber = defaultValue
    ? parsePhoneNumber(defaultValue)
    : undefined;

  const [countryCode, setCountryCode] = useState<ISO3166Alpha2>(
    (defaultNumber?.country as ISO3166Alpha2) || defaultCountryCode
  );

  const selectedCallcode = getCallCodeForCountry(countryCode);
  const [numberWithoutExtension, setNumberWithoutExtension] = useState(
    defaultNumber?.nationalNumber || ''
  );
  const parsedPhoneNumber = parsePhoneNumber(
    '+' + selectedCallcode?.callCode + numberWithoutExtension
  );

  const nationalNumber = parsedPhoneNumber?.nationalNumber;
  const internationalNumber = parsedPhoneNumber?.number;

  const mappedCallCodes = useMemo(
    () =>
      callCodeArray
        .map((callCode) => ({
          label: `(+${callCode.callCode}) ${countryCodeToName(
            callCode.countryCode,
            language
          )}`,
          value: callCode.countryCode,
          data: {
            name: countryCodeToName(callCode.countryCode, language) || '',
          },
        }))
        .sort((a, b) => a.data.name.localeCompare(b.data.name)),
    [language]
  );

  const example = getPhoneNumberExample(countryCode);
  const formattedExample = formatPhoneNumber(
    '+' + selectedCallcode?.callCode + example
  ).trim();

  useEffect(() => {
    onValueChange?.({
      nationalNumber,
      internationalNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internationalNumber, nationalNumber]);

  return (
    <div className="grid gap-1">
      <p
        className={cn(
          'text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor',
          error && 'text-theme-inputs-errorColor'
        )}
      >
        {label}
      </p>
      <div className="flex gap-2">
        <ThemedSearchableSelect
          values={[countryCode]}
          emptyText={emptyText}
          placeholder={placeholder}
          error={!!error}
          onValuesChange={([v]) => {
            setCountryCode(v as ISO3166Alpha2);
          }}
          options={mappedCallCodes}
          renderOption={(option) => (
            <div className="flex items-center gap-2">
              <div className="bg-theme-typography-paragraph-textColor/20 text-theme-inputs-fontSize size-5 overflow-hidden rounded-full">
                <CircleFlag countryCode={option.value} />
              </div>
              <span>{option.label}</span>
            </div>
          )}
          renderValues={([countryCode]) => {
            const callCode = getCallCodeForCountry(
              countryCode as ISO3166Alpha2
            );
            return (
              <div className="text-theme-inputs-fontSize flex items-center gap-2">
                <div className="size-5 overflow-hidden rounded-full">
                  <CircleFlag countryCode={countryCode as string} />
                </div>
                (+{callCode?.callCode})
              </div>
            );
          }}
        />

        <div className="w-full">
          <ThemedInput
            type="tel"
            onValueChange={setNumberWithoutExtension}
            value={numberWithoutExtension}
            error={!!error}
            aria-label={label}
            placeholder={formattedExample}
          />
        </div>
      </div>
      {(hint || error) && (
        <p
          className={cn(
            'text-theme-inputs-labelTextColor text-theme-inputs-hintAndErrorFontSize',
            error &&
              'text-theme-inputs-errorColor text-theme-inputs-hintAndErrorFontSize'
          )}
        >
          {error || hint}
        </p>
      )}
    </div>
  );
};
