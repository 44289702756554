import { memo, useEffect, useState } from 'react';
import { cva } from 'class-variance-authority';

const styleClasses = cva('', {
  variants: {
    environment: {
      development: 'bg-kiwi-light',
      staging: 'bg-mangosteen',
      sandbox: 'bg-tangerine',
    },
  },
});

export type EnvironmentIndicatorProps = {
  environment?: 'development' | 'staging' | 'sandbox' | 'production';
};

export const EnvironmentIndicator = memo(
  ({ environment }: EnvironmentIndicatorProps) => {
    if (!environment || environment === 'production') return null;

    return (
      <div
        aria-hidden
        className={styleClasses({
          environment,
          className: 'pointer-events-none fixed inset-x-0 top-0 h-1',
        })}
      >
        <div
          className={styleClasses({
            environment,
            className:
              'absolute left-1/2 -translate-x-1/2 rounded-b-md px-2 py-1 text-xs text-ink',
          })}
        >
          {environment}
        </div>
      </div>
    );
  }
);

EnvironmentIndicator.displayName = 'EnvironmentIndicator';

export const AutomaticEnvironmentIndicator = memo(() => {
  const [environment, setEnvironment] =
    useState<EnvironmentIndicatorProps['environment']>('production');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setEnvironment(getBitsEnvironment());
    }
  }, []);

  return <EnvironmentIndicator environment={environment} />;
});

AutomaticEnvironmentIndicator.displayName = 'AutomaticEnvironmentIndicator';

export const getBitsEnvironment = () => {
  if (typeof window !== 'undefined') {
    const hostname = window.location.hostname;

    if (hostname === 'localhost' || hostname.includes('.local')) {
      return 'development';
    } else if (hostname.includes('bits.beer')) {
      return 'staging';
    } else if (hostname.includes('bits.builders')) {
      return 'sandbox';
    }
  }

  return 'production';
};
