import { setFeatureFlagContext } from '@bits/feature-flags';

import { getBitsEnvironment } from '@/lib/get-bits-environment';
import { useCurrentUser } from '@/lib/hooks/use-current-user';
import { useIsomorphicLayoutEffect } from '@/lib/use-isomorphic-layout-effect';

export const FeatureFlagContextSetters = () => {
  const { isBitsEmployee, email } = useCurrentUser();

  useIsomorphicLayoutEffect(() => {
    setFeatureFlagContext({ environment: getBitsEnvironment() });
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (email) {
      setFeatureFlagContext({ email });
    }
  }, [email]);

  useIsomorphicLayoutEffect(() => {
    if (isBitsEmployee) {
      // eslint-disable-next-line no-console
      setFeatureFlagContext({ isBitsEmployee }).catch(console.error);
    }
  }, [isBitsEmployee]);

  return null;
};
