import * as React from 'react';

import { cn } from './utils/cn';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'transition-colors flex h-11 w-full color-ink rounded-md border border-input bg-transparent px-3 py-2 bits-text-body-1 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-smoke focus-visible:outline-none focus-visible:border-ink disabled:cursor-not-allowed disabled:bg-fog disabled:text-smoke',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
