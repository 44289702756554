'use client';

import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { CaretUpDown, Check } from '@phosphor-icons/react';
import clsx from 'clsx';

import { Popover, PopoverPrimitive, PopoverTrigger } from '../popover';
import { cn } from '../utils/cn';
import { useUuid } from '../utils/use-uuid';
import { VirtualizedList } from '../virtualized';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './themed-command';

export type SearchableSelectProps<
  TOptionData,
  TOption = { label: string; value: string; data?: TOptionData },
> = {
  options: TOption[];
  multiselect?: boolean;
  values?: string[];
  error?: string | boolean;
  label?: string;
  hint?: string;
  onValuesChange?: (value: string[]) => void;
  renderOption?: (option: TOption, values: string[]) => ReactNode;
  renderValues?: (values: string[], options: TOption[]) => ReactNode;
  placeholder: string;
  emptyText: string;
  searchable?: boolean;
  required?: boolean;
};

/**
 * A controlled searchable select component, combobox.
 *
 * @example
 * const [values, setValues] = useState<string[]>(['option1']);
 * ...
 * <SearchableSelect
 *   values={values}
 *   options={[
 *     { label: 'Option 1', value: 'option1' },
 *     { label: 'Option 2', value: 'option2' },
 *     { label: 'Option 3', value: 'option3' },
 *   ]}
 *   multiselect
 *   placeholder="Select options"
 *   emptyText="No options found"
 *   onValuesChange={setValues}
 * />
 */
export function ThemedSearchableSelect<
  TOptionData extends Record<string, unknown>,
  TOption extends { label: string; value: string; data?: TOptionData },
>({
  options,
  multiselect,
  values = [],
  error,
  label,
  hint,
  onValuesChange,
  renderOption: _renderOption,
  renderValues: _renderValues,
  searchable = true,
  placeholder,
  emptyText,
  required,
}: SearchableSelectProps<TOptionData>) {
  const [open, setOpen] = useState(false);

  const renderOption = useMemo(
    () =>
      _renderOption
        ? _renderOption
        : (option: TOption, values: string[]) => {
            return (
              <>
                <span
                  className={clsx(
                    'mr-2 flex size-5 shrink-0 items-center justify-center rounded-md',
                    multiselect && [
                      'border-theme-inputs-checkboxesAndRadios-unchecked-borderColor',
                      '[border-width:max(var(--inputs-borderWidth,1px),1px)]',
                      'text-theme-inputs-checkboxesAndRadios-indicatorColor',
                      values.includes(option.value)
                        ? 'bg-theme-inputs-checkboxesAndRadios-checked-backgroundColor'
                        : 'bg-theme-inputs-checkboxesAndRadios-unchecked-backgroundColor',
                    ],
                    !multiselect && ['text-theme-inputs-textColor']
                  )}
                >
                  <Check
                    weight="bold"
                    className={cn(
                      'h-4 w-4',
                      values.includes(option.value)
                        ? 'opacity-100'
                        : 'opacity-0'
                    )}
                  />
                </span>
                <span title={option.label} className="truncate">
                  {option.label}
                </span>
              </>
            );
          },
    [_renderOption, multiselect]
  );

  const renderValues = useMemo(
    () =>
      _renderValues
        ? _renderValues
        : (values: string[], options: TOption[]) => {
            return values
              .map((val) => options.find((i) => i.value === val)?.label ?? '')
              .map((val) => (
                <div className="w-full truncate" key={val} title={val}>
                  {val}
                </div>
              ));
          },
    [_renderValues]
  );

  useEffect(() => {
    if (open) setFilterValue('');
  }, [open]);

  const contentId = useUuid();
  const errorId = useUuid();
  const hintId = useUuid();
  const labelId = useUuid();

  const [filterValue, setFilterValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          option.label.toLowerCase().includes(filterValue.toLowerCase()) ||
          option.value.toLowerCase().includes(filterValue.toLowerCase())
      ),
    [options, filterValue]
  );

  return (
    <div className="w-full overflow-hidden">
      {label && (
        <p
          className={cn(
            'text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor mb-2',
            error && 'text-theme-inputs-errorColor'
          )}
          id={labelId}
        >
          {label}
        </p>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <button
            role="combobox"
            aria-required={!!required}
            aria-expanded={open}
            aria-invalid={!!error}
            aria-describedby={error ? errorId : hintId}
            aria-controls={contentId}
            aria-labelledby={labelId}
            className={clsx(
              'bits-text-body-1 flex min-h-11 w-full items-center justify-between px-3 py-2 text-left transition-colors ',
              'text-theme-inputs-normal-textColor border-theme-inputs-borderColor bg-theme-inputs-backgroundColor',
              'leading-normal [border-radius:var(--inputs-cornerRadius)] [border-width:var(--inputs-borderWidth)]',
              'disabled:cursor-not-allowed disabled:opacity-50',
              'outline-none outline-offset-0 focus-visible:border-theme-general-focusColor focus-visible:outline-1 focus-visible:outline-theme-general-focusColor',
              'text-theme-inputs-textColor',
              error &&
                'border-theme-inputs-errorColor focus-visible:!outline-theme-inputs-errorColor'
            )}
          >
            <div className="overflow-hidden">
              {values.length
                ? renderValues(values, options as TOption[])
                : placeholder}
            </div>
            <CaretUpDown className="ml-2 size-4 shrink-0 opacity-50" />
          </button>
        </PopoverTrigger>
        <PopoverPrimitive.Portal>
          <PopoverPrimitive.Content
            align="start"
            className={cn(
              'relative overflow-hidden z-10',
              'animate-in fade-in-0 data-[side=bottom]:mt-1 data-[side=top]:mb-1',
              'data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2',
              'p-0 border-theme-inputs-borderColor [border-width:var(--inputs-borderWidth)]',
              'min-w-[var(--radix-popover-trigger-width)] max-w-[calc(var(--radix-popper-available-width)-16px)] max-h-[calc(var(--radix-popper-available-height)-16px)]'
            )}
            id={contentId}
            asChild
          >
            <Command
              shouldFilter={false}
              className="relative h-full overflow-hidden"
            >
              {searchable && (
                <>
                  <CommandInput
                    disabled={!searchable}
                    placeholder={placeholder}
                    ref={inputRef}
                    value={filterValue}
                    onValueChange={setFilterValue}
                  />
                  <CommandEmpty>{emptyText}</CommandEmpty>
                </>
              )}
              <CommandGroup>
                <VirtualizedList
                  className="size-full max-h-[calc(var(--radix-popper-available-height)-80px)]"
                  rows={filteredOptions}
                  rowHeight={40}
                  padding={200}
                  renderRow={({ style, row, index }) => (
                    <div style={style} className="w-full" key={index}>
                      <CommandItem
                        key={row.label}
                        value={row.label}
                        className="bg-theme-inputs-backgroundColor text-theme-inputs-textColor"
                        onSelect={() => {
                          if (multiselect) {
                            if (values.includes(row.value)) {
                              onValuesChange?.(
                                values.filter((value) => value !== row.value)
                              );
                            } else {
                              const newValues = [...values, row.value];
                              onValuesChange?.(newValues);
                            }
                          } else {
                            onValuesChange?.([row.value]);
                            setOpen(false);
                          }
                        }}
                      >
                        {renderOption(row as TOption, values)}
                      </CommandItem>
                    </div>
                  )}
                />
              </CommandGroup>
            </Command>
          </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
      </Popover>
      {hint && (
        <p
          className={cn(
            'text-theme-inputs-hintTextColor text-theme-inputs-hintAndErrorFontSize mt-1'
          )}
          id={hintId}
        >
          {hint}
        </p>
      )}
      {error && (
        <p
          className={cn(
            'text-theme-inputs-hintTextColor text-theme-inputs-hintAndErrorFontSize mt-1',
            'text-theme-inputs-errorColor'
          )}
          id={errorId}
        >
          {error}
        </p>
      )}
    </div>
  );
}
