import { useEffect, useState } from 'react';
import { MagnifyingGlass, X } from '@phosphor-icons/react';

import { cn } from '../utils/cn';

export interface SearchInputProps {
  /**
   * The value of the input.
   */
  value?: string;
  /**
   * Callback to be called when the value changes.
   * @param value - The new value.
   */
  onValueChange?: (value: string) => void;
  /**
   * Debounce time in milliseconds.
   */
  debounce?: number;
}

/**
 * Search input component.
 *
 * @example
 * ```tsx
 * <SearchInput value={query} onValueChange={setQuery} />
 * ```
 */
export const SearchInput = ({
  value = '',
  onValueChange,
  debounce = 350,
}: SearchInputProps) => {
  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    setInternalValue(value || '');
  }, [value]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onValueChange && value !== internalValue) {
        onValueChange(internalValue);
      }
    }, debounce);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalValue]);

  return (
    <div className="relative">
      <input
        type="search"
        placeholder="Search"
        className={cn(
          'h-9 w-[170px] p-2 pl-7 rounded-lg border border-fog bg-white',
          'placeholder:text-smoke',
          'focus-within:outline-none focus-within:ring-2 focus-within:ring-smoke',
          '[&::-webkit-search-cancel-button]:hidden [&::ms-clear]:hidden'
        )}
        value={internalValue}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (onValueChange) {
              onValueChange(internalValue);
            }
          }
        }}
        onChange={(e) => {
          setInternalValue(e.target.value);
        }}
      />

      <div className="text-smoke absolute left-2 top-1/2 -translate-y-1/2">
        {internalValue && (
          <button
            className={cn(
              'text-ink  -ml-1 block rounded-full p-1',
              'hover:bg-fog focus-within:bg-fog focus-within:outline-none focus-within:ring-2 focus-within:ring-smoke'
            )}
            aria-label="Clear search input"
            onClick={() => {
              if (onValueChange) {
                onValueChange('');
                setInternalValue('');
              }
            }}
          >
            <X className="size-4" />
          </button>
        )}
        {!internalValue && <MagnifyingGlass weight="bold" className="size-4" />}
      </div>
    </div>
  );
};
