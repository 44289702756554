import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import questionnaireDA from '../public/locales/da/questionnaire.json';
import common from '../public/locales/en/common.json';
import entities from '../public/locales/en/entities.json';
import errors from '../public/locales/en/errors.json';
import login from '../public/locales/en/login.json';
import monitoring from '../public/locales/en/monitoring.json';
import onboarding from '../public/locales/en/onboarding.json';
import questionnaireEN from '../public/locales/en/questionnaire.json';
import reports from '../public/locales/en/reports.json';
import risk from '../public/locales/en/risk.json';
import workflows from '../public/locales/en/workflows.json';
import questionnaireFI from '../public/locales/fi/questionnaire.json';
import questionnaireNO from '../public/locales/no/questionnaire.json';
import questionnaireSV from '../public/locales/sv/questionnaire.json';

export const resources = {
  en: {
    common,
    entities,
    login,
    onboarding,
    questionnaire: questionnaireEN,
    reports,
    monitoring,
    workflows,
    risk,
    errors,
  },
  fi: {
    questionnaire: questionnaireFI,
  },
  sv: {
    questionnaire: questionnaireSV,
  },
  da: {
    questionnaire: questionnaireDA,
  },
  no: {
    questionnaire: questionnaireNO,
  },
};

export const backendResources = [
  'shared',
  'kyb-questionnaire',
  'kyc-questionnaire',
  'industry-codes',
];

export const i18nBackendLoadpath = '/api/locales/{{lng}}/{{ns}}';

i18n
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en', 'sv', 'fi'],
    partialBundledLanguages: true,
    resources,
    defaultNS: 'common',
    ns: [
      // Local namespaces
      'common',
      'workflows',
      'onboarding',
      'questionnaire',
      'industry-codes',
      'reports',
      'login',
      'monitoring',
      'entities',
      'risk',
      'errors',

      // Backend namespaces
      ...backendResources,
    ],
    backend: {
      loadPath: i18nBackendLoadpath,
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .then();

export default i18n;
