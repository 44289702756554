import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import clsx from 'clsx';

import { SmallSpinner } from '../small-spinner';

export interface ThemedButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  children: ReactNode;
  loading?: boolean;
}

export const ThemedButton = React.forwardRef<
  HTMLButtonElement,
  ThemedButtonProps
>(
  (
    {
      asChild = false,
      className,
      icon,
      iconPosition = 'left',
      children,
      loading,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={clsx(
          'inline-flex relative justify-center gap-2 items-center transition-all duration-75 p-6 py-3 min-w-[120px] overflow-hidden',
          '[letter-spacing:var(--buttons-primary-letterSpacing)] [text-transform:var(--buttons-primary-uppercase)]',
          '[box-shadow:var(--buttons-primary-shadow)] [font-weight:var(--buttons-primary-fontWeight)]',
          'rounded-[var(--buttons-primary-cornerRadius)] [border-width:var(--buttons-primary-borderWidth)]',
          'text-theme-buttons-primary-fontSize',
          'bg-theme-buttons-primary-normal-backgroundColor text-theme-buttons-primary-normal-textColor border-theme-buttons-primary-normal-borderColor',
          'hover:text-theme-buttons-primary-hover-textColor hover:bg-theme-buttons-primary-hover-backgroundColor hover:border-theme-buttons-primary-hover-borderColor',
          'active:bg-theme-buttons-primary-hover-backgroundColor/50 active:transition-none',
          'aria-disabled:opacity-50 aria-disabled:pointer-events-none text-pretty text-center',
          'outline-none focus-visible:outline-2 focus-visible:outline-theme-general-focusColor',
          '[&_svg]:w-4 [&_svg]:h-4',
          className
        )}
        {...props}
        disabled={props.disabled || loading}
        aria-disabled={props.disabled || loading}
        ref={ref}
      >
        {iconPosition === 'left' && icon}
        <Slottable>{children}</Slottable>
        {iconPosition === 'right' && icon}
        {loading && (
          <div className="absolute inset-0 flex bg-theme-buttons-primary-normal-backgroundColor">
            <SmallSpinner className="m-auto" />
          </div>
        )}
      </Comp>
    );
  }
);
ThemedButton.displayName = 'ThemedButton';
