import { CSSProperties } from 'react';
import bitsColors from '@bits/tailwind-config/bits-colors';

import { cn } from './utils/cn';

export const ScoreRadios = ({
  max = 4,
  min = 1,
  onValueChange,
  value,
  colors = [
    bitsColors['kiwi-light'],
    bitsColors['khaki'],
    bitsColors['tangerine-light'],
    bitsColors['grapefruit'],
  ],
}: {
  max?: number;
  min?: number;
  onValueChange: (score: number) => void;
  value: number | undefined | null;
  colors?: string[];
}) => {
  return (
    <div className="flex w-full gap-4" role="radiogroup">
      {Array.from({ length: max - min + 1 }, (_, i) => i + min).map((score) => (
        <button
          type="button"
          style={
            {
              '--color': colors?.[score - 1],
            } as CSSProperties
          }
          className={cn(
            'bits-text-button-1 w-full rounded-md px-4 h-10 items-center justify-center text-ink border',
            'transition-all duration-50',
            value !== score &&
              'hover:bg-[color-mix(in_srgb,var(--color),transparent)]',
            value === score
              ? 'bg-[var(--color)] border-[var(--color)]'
              : 'bg-white'
          )}
          key={score}
          onClick={() => onValueChange(score)}
          role={'radio'}
          aria-checked={value === score}
        >
          {score}
        </button>
      ))}
    </div>
  );
};
