import { ISO3166Alpha2 } from '@bits/i18n';

export const callCodes: Record<
  string,
  {
    callCode: string;
    countryCode: ISO3166Alpha2;
  }
> = {
  'AU+61': {
    callCode: '61',
    countryCode: 'AU',
  },
  'AT+43': {
    callCode: '43',
    countryCode: 'AT',
  },
  'AZ+994': {
    callCode: '994',
    countryCode: 'AZ',
  },
  'AL+355': {
    callCode: '355',
    countryCode: 'AL',
  },
  'DZ+213': {
    callCode: '213',
    countryCode: 'DZ',
  },
  'AS+1': {
    callCode: '1',
    countryCode: 'AS',
  },
  'AI+1': {
    callCode: '1',
    countryCode: 'AI',
  },
  'AO+244': {
    callCode: '244',
    countryCode: 'AO',
  },
  'AD+376': {
    callCode: '376',
    countryCode: 'AD',
  },
  'AQ+672': {
    callCode: '672',
    countryCode: 'AQ',
  },
  'AG+1': {
    callCode: '1',
    countryCode: 'AG',
  },
  'AE+971': {
    callCode: '971',
    countryCode: 'AE',
  },
  'AR+54': {
    callCode: '54',
    countryCode: 'AR',
  },
  'AM+374': {
    callCode: '374',
    countryCode: 'AM',
  },
  'AW+297': {
    callCode: '297',
    countryCode: 'AW',
  },
  'AW+599': {
    callCode: '599',
    countryCode: 'AW',
  },
  'AF+93': {
    callCode: '93',
    countryCode: 'AF',
  },
  'BS+1': {
    callCode: '1',
    countryCode: 'BS',
  },
  'BD+880': {
    callCode: '880',
    countryCode: 'BD',
  },
  'BB+1': {
    callCode: '1',
    countryCode: 'BB',
  },
  'BH+973': {
    callCode: '973',
    countryCode: 'BH',
  },
  'BY+375': {
    callCode: '375',
    countryCode: 'BY',
  },
  'BZ+501': {
    callCode: '501',
    countryCode: 'BZ',
  },
  'BE+32': {
    callCode: '32',
    countryCode: 'BE',
  },
  'BJ+229': {
    callCode: '229',
    countryCode: 'BJ',
  },
  'BM+1': {
    callCode: '1',
    countryCode: 'BM',
  },
  'BG+359': {
    callCode: '359',
    countryCode: 'BG',
  },
  'BO+591': {
    callCode: '591',
    countryCode: 'BO',
  },
  'BA+387': {
    callCode: '387',
    countryCode: 'BA',
  },
  'BW+267': {
    callCode: '267',
    countryCode: 'BW',
  },
  'BR+55': {
    callCode: '55',
    countryCode: 'BR',
  },
  'IO+246': {
    callCode: '246',
    countryCode: 'IO',
  },
  'BN+673': {
    callCode: '673',
    countryCode: 'BN',
  },
  'BF+226': {
    callCode: '226',
    countryCode: 'BF',
  },
  'BI+257': {
    callCode: '257',
    countryCode: 'BI',
  },
  'BT+975': {
    callCode: '975',
    countryCode: 'BT',
  },
  'VU+678': {
    callCode: '678',
    countryCode: 'VU',
  },
  'VA+39': {
    callCode: '39',
    countryCode: 'VA',
  },
  'GB+44': {
    callCode: '44',
    countryCode: 'GB',
  },
  'HU+36': {
    callCode: '36',
    countryCode: 'HU',
  },
  'VE+58': {
    callCode: '58',
    countryCode: 'VE',
  },
  'VG+1': {
    callCode: '1',
    countryCode: 'VG',
  },
  'VI+1': {
    callCode: '1',
    countryCode: 'VI',
  },
  'TL+670': {
    callCode: '670',
    countryCode: 'TL',
  },
  'VN+84': {
    callCode: '84',
    countryCode: 'VN',
  },
  'GA+241': {
    callCode: '241',
    countryCode: 'GA',
  },
  'HT+509': {
    callCode: '509',
    countryCode: 'HT',
  },
  'GY+592': {
    callCode: '592',
    countryCode: 'GY',
  },
  'GM+220': {
    callCode: '220',
    countryCode: 'GM',
  },
  'GH+233': {
    callCode: '233',
    countryCode: 'GH',
  },
  'GP+590': {
    callCode: '590',
    countryCode: 'GP',
  },
  'GT+502': {
    callCode: '502',
    countryCode: 'GT',
  },
  'GN+224': {
    callCode: '224',
    countryCode: 'GN',
  },
  'GW+245': {
    callCode: '245',
    countryCode: 'GW',
  },
  'DE+49': {
    callCode: '49',
    countryCode: 'DE',
  },
  'GI+350': {
    callCode: '350',
    countryCode: 'GI',
  },
  'HN+504': {
    callCode: '504',
    countryCode: 'HN',
  },
  'HK+852': {
    callCode: '852',
    countryCode: 'HK',
  },
  'GD+1': {
    callCode: '1',
    countryCode: 'GD',
  },
  'GL+299': {
    callCode: '299',
    countryCode: 'GL',
  },
  'GR+30': {
    callCode: '30',
    countryCode: 'GR',
  },
  'GE+995': {
    callCode: '995',
    countryCode: 'GE',
  },
  'GU+1': {
    callCode: '1',
    countryCode: 'GU',
  },
  'DK+45': {
    callCode: '45',
    countryCode: 'DK',
  },
  'CD+243': {
    callCode: '243',
    countryCode: 'CD',
  },
  'DJ+253': {
    callCode: '253',
    countryCode: 'DJ',
  },
  'DM+1': {
    callCode: '1',
    countryCode: 'DM',
  },
  'DO+1': {
    callCode: '1',
    countryCode: 'DO',
  },
  'EG+20': {
    callCode: '20',
    countryCode: 'EG',
  },
  'ZM+260': {
    callCode: '260',
    countryCode: 'ZM',
  },
  'EH+212': {
    callCode: '212',
    countryCode: 'EH',
  },
  'ZW+263': {
    callCode: '263',
    countryCode: 'ZW',
  },
  'IL+972': {
    callCode: '972',
    countryCode: 'IL',
  },
  'IN+91': {
    callCode: '91',
    countryCode: 'IN',
  },
  'ID+62': {
    callCode: '62',
    countryCode: 'ID',
  },
  'JO+962': {
    callCode: '962',
    countryCode: 'JO',
  },
  'IQ+964': {
    callCode: '964',
    countryCode: 'IQ',
  },
  'IR+98': {
    callCode: '98',
    countryCode: 'IR',
  },
  'IE+353': {
    callCode: '353',
    countryCode: 'IE',
  },
  'IS+354': {
    callCode: '354',
    countryCode: 'IS',
  },
  'ES+34': {
    callCode: '34',
    countryCode: 'ES',
  },
  'IT+39': {
    callCode: '39',
    countryCode: 'IT',
  },
  'YE+967': {
    callCode: '967',
    countryCode: 'YE',
  },
  'KZ+7': {
    callCode: '7',
    countryCode: 'KZ',
  },
  'KY+1': {
    callCode: '1',
    countryCode: 'KY',
  },
  'KH+855': {
    callCode: '855',
    countryCode: 'KH',
  },
  'CM+237': {
    callCode: '237',
    countryCode: 'CM',
  },
  'CA+1': {
    callCode: '1',
    countryCode: 'CA',
  },
  'QA+974': {
    callCode: '974',
    countryCode: 'QA',
  },
  'KE+254': {
    callCode: '254',
    countryCode: 'KE',
  },
  'CY+357': {
    callCode: '357',
    countryCode: 'CY',
  },
  'KI+686': {
    callCode: '686',
    countryCode: 'KI',
  },
  'CN+86': {
    callCode: '86',
    countryCode: 'CN',
  },
  // PRE 1994 lol
  // 'CC+672': {
  //   callCode: '672',
  //   countryCode: 'CC',
  // },
  'CC+61': {
    callCode: '61',
    countryCode: 'CC',
  },
  'CO+57': {
    callCode: '57',
    countryCode: 'CO',
  },
  'KM+269': {
    callCode: '269',
    countryCode: 'KM',
  },
  'CG+242': {
    callCode: '242',
    countryCode: 'CG',
  },
  'KP+850': {
    callCode: '850',
    countryCode: 'KP',
  },
  'KR+82': {
    callCode: '82',
    countryCode: 'KR',
  },
  'CR+506': {
    callCode: '506',
    countryCode: 'CR',
  },
  'CI+225': {
    callCode: '225',
    countryCode: 'CI',
  },
  'CU+53': {
    callCode: '53',
    countryCode: 'CU',
  },
  'KW+965': {
    callCode: '965',
    countryCode: 'KW',
  },
  'KG+996': {
    callCode: '996',
    countryCode: 'KG',
  },
  'LA+856': {
    callCode: '856',
    countryCode: 'LA',
  },
  'LV+371': {
    callCode: '371',
    countryCode: 'LV',
  },
  'LS+266': {
    callCode: '266',
    countryCode: 'LS',
  },
  'LR+231': {
    callCode: '231',
    countryCode: 'LR',
  },
  'LB+961': {
    callCode: '961',
    countryCode: 'LB',
  },
  'LY+218': {
    callCode: '218',
    countryCode: 'LY',
  },
  'LT+370': {
    callCode: '370',
    countryCode: 'LT',
  },
  'LI+423': {
    callCode: '423',
    countryCode: 'LI',
  },
  'LU+352': {
    callCode: '352',
    countryCode: 'LU',
  },
  'MU+230': {
    callCode: '230',
    countryCode: 'MU',
  },
  'MR+222': {
    callCode: '222',
    countryCode: 'MR',
  },
  'MG+261': {
    callCode: '261',
    countryCode: 'MG',
  },
  'YT+262': {
    callCode: '262',
    countryCode: 'YT',
  },
  'MO+853': {
    callCode: '853',
    countryCode: 'MO',
  },
  'MK+389': {
    callCode: '389',
    countryCode: 'MK',
  },
  'MW+265': {
    callCode: '265',
    countryCode: 'MW',
  },
  'MY+60': {
    callCode: '60',
    countryCode: 'MY',
  },
  'ML+223': {
    callCode: '223',
    countryCode: 'ML',
  },
  'MV+960': {
    callCode: '960',
    countryCode: 'MV',
  },
  'MT+356': {
    callCode: '356',
    countryCode: 'MT',
  },
  'MP+1': {
    callCode: '1',
    countryCode: 'MP',
  },
  'MA+212': {
    callCode: '212',
    countryCode: 'MA',
  },
  'MQ+596': {
    callCode: '596',
    countryCode: 'MQ',
  },
  'MH+692': {
    callCode: '692',
    countryCode: 'MH',
  },
  'MX+52': {
    callCode: '52',
    countryCode: 'MX',
  },
  'FM+691': {
    callCode: '691',
    countryCode: 'FM',
  },
  'MZ+258': {
    callCode: '258',
    countryCode: 'MZ',
  },
  'MD+373': {
    callCode: '373',
    countryCode: 'MD',
  },
  'MC+377': {
    callCode: '377',
    countryCode: 'MC',
  },
  'MN+976': {
    callCode: '976',
    countryCode: 'MN',
  },
  'MS+1': {
    callCode: '1',
    countryCode: 'MS',
  },
  'MM+95': {
    callCode: '95',
    countryCode: 'MM',
  },
  'NA+264': {
    callCode: '264',
    countryCode: 'NA',
  },
  'NR+674': {
    callCode: '674',
    countryCode: 'NR',
  },
  'NP+977': {
    callCode: '977',
    countryCode: 'NP',
  },
  'NE+227': {
    callCode: '227',
    countryCode: 'NE',
  },
  'NG+234': {
    callCode: '234',
    countryCode: 'NG',
  },
  'NL+31': {
    callCode: '31',
    countryCode: 'NL',
  },
  'NI+505': {
    callCode: '505',
    countryCode: 'NI',
  },
  'NU+683': {
    callCode: '683',
    countryCode: 'NU',
  },
  'NZ+64': {
    callCode: '64',
    countryCode: 'NZ',
  },
  'NC+687': {
    callCode: '687',
    countryCode: 'NC',
  },
  'NO+47': {
    callCode: '47',
    countryCode: 'NO',
  },
  'OM+968': {
    callCode: '968',
    countryCode: 'OM',
  },
  'BV+47': {
    callCode: '47',
    countryCode: 'BV',
  },
  'IM+44': {
    callCode: '44',
    countryCode: 'IM',
  },
  'NF+672': {
    callCode: '672',
    countryCode: 'NF',
  },
  'PN+64': {
    callCode: '64',
    countryCode: 'PN',
  },
  'CX+61': {
    callCode: '61',
    countryCode: 'CX',
  },
  'SH+290': {
    callCode: '290',
    countryCode: 'SH',
  },
  'WF+681': {
    callCode: '681',
    countryCode: 'WF',
  },
  'HM+61': {
    callCode: '61',
    countryCode: 'HM',
  },
  'CV+238': {
    callCode: '238',
    countryCode: 'CV',
  },
  'CK+682': {
    callCode: '682',
    countryCode: 'CK',
  },
  'WS+685': {
    callCode: '685',
    countryCode: 'WS',
  },
  'SJ+47': {
    callCode: '47',
    countryCode: 'SJ',
  },
  'TC+1': {
    callCode: '1',
    countryCode: 'TC',
  },
  'UM+1': {
    callCode: '1',
    countryCode: 'UM',
  },
  'PK+92': {
    callCode: '92',
    countryCode: 'PK',
  },
  'PW+680': {
    callCode: '680',
    countryCode: 'PW',
  },
  'PS+970': {
    callCode: '970',
    countryCode: 'PS',
  },
  'PA+507': {
    callCode: '507',
    countryCode: 'PA',
  },
  'PG+675': {
    callCode: '675',
    countryCode: 'PG',
  },
  'PY+595': {
    callCode: '595',
    countryCode: 'PY',
  },
  'PE+51': {
    callCode: '51',
    countryCode: 'PE',
  },
  'PL+48': {
    callCode: '48',
    countryCode: 'PL',
  },
  'PT+351': {
    callCode: '351',
    countryCode: 'PT',
  },
  'PR+1': {
    callCode: '1',
    countryCode: 'PR',
  },
  'RE+262': {
    callCode: '262',
    countryCode: 'RE',
  },
  'RU+7': {
    callCode: '7',
    countryCode: 'RU',
  },
  'RW+250': {
    callCode: '250',
    countryCode: 'RW',
  },
  'RO+40': {
    callCode: '40',
    countryCode: 'RO',
  },
  'SV+503': {
    callCode: '503',
    countryCode: 'SV',
  },
  'SM+378': {
    callCode: '378',
    countryCode: 'SM',
  },
  'ST+239': {
    callCode: '239',
    countryCode: 'ST',
  },
  'SA+966': {
    callCode: '966',
    countryCode: 'SA',
  },
  'SZ+268': {
    callCode: '268',
    countryCode: 'SZ',
  },
  'SC+248': {
    callCode: '248',
    countryCode: 'SC',
  },
  'SN+221': {
    callCode: '221',
    countryCode: 'SN',
  },
  'PM+508': {
    callCode: '508',
    countryCode: 'PM',
  },
  'VC+1784': {
    callCode: '1784',
    countryCode: 'VC',
  },
  'KN+1': {
    callCode: '1',
    countryCode: 'KN',
  },
  'LC+1': {
    callCode: '1',
    countryCode: 'LC',
  },
  'SG+65': {
    callCode: '65',
    countryCode: 'SG',
  },
  'SY+963': {
    callCode: '963',
    countryCode: 'SY',
  },
  'SK+421': {
    callCode: '421',
    countryCode: 'SK',
  },
  'SI+386': {
    callCode: '386',
    countryCode: 'SI',
  },
  'US+1': {
    callCode: '1',
    countryCode: 'US',
  },
  'SB+677': {
    callCode: '677',
    countryCode: 'SB',
  },
  'SO+252': {
    callCode: '252',
    countryCode: 'SO',
  },
  'SD+249': {
    callCode: '249',
    countryCode: 'SD',
  },
  'SR+597': {
    callCode: '597',
    countryCode: 'SR',
  },
  'SL+232': {
    callCode: '232',
    countryCode: 'SL',
  },
  'TJ+992': {
    callCode: '992',
    countryCode: 'TJ',
  },
  'TW+886': {
    callCode: '886',
    countryCode: 'TW',
  },
  'TH+66': {
    callCode: '66',
    countryCode: 'TH',
  },
  'TZ+255': {
    callCode: '255',
    countryCode: 'TZ',
  },
  'TG+228': {
    callCode: '228',
    countryCode: 'TG',
  },
  'TK+690': {
    callCode: '690',
    countryCode: 'TK',
  },
  'TO+676': {
    callCode: '676',
    countryCode: 'TO',
  },
  'TT+1': {
    callCode: '1',
    countryCode: 'TT',
  },
  'TV+688': {
    callCode: '688',
    countryCode: 'TV',
  },
  'TN+216': {
    callCode: '216',
    countryCode: 'TN',
  },
  'TM+993': {
    callCode: '993',
    countryCode: 'TM',
  },
  'TR+90': {
    callCode: '90',
    countryCode: 'TR',
  },
  'UG+256': {
    callCode: '256',
    countryCode: 'UG',
  },
  'UZ+998': {
    callCode: '998',
    countryCode: 'UZ',
  },
  'UA+380': {
    callCode: '380',
    countryCode: 'UA',
  },
  'UY+598': {
    callCode: '598',
    countryCode: 'UY',
  },
  'FO+298': {
    callCode: '298',
    countryCode: 'FO',
  },
  'FJ+679': {
    callCode: '679',
    countryCode: 'FJ',
  },
  'PH+63': {
    callCode: '63',
    countryCode: 'PH',
  },
  'FI+358': {
    callCode: '358',
    countryCode: 'FI',
  },
  'FK+500': {
    callCode: '500',
    countryCode: 'FK',
  },
  'FR+33': {
    callCode: '33',
    countryCode: 'FR',
  },
  'GF+594': {
    callCode: '594',
    countryCode: 'GF',
  },
  'PF+689': {
    callCode: '689',
    countryCode: 'PF',
  },
  'TF+1': {
    callCode: '1',
    countryCode: 'TF',
  },
  'HR+385': {
    callCode: '385',
    countryCode: 'HR',
  },
  'CF+236': {
    callCode: '236',
    countryCode: 'CF',
  },
  'TD+235': {
    callCode: '235',
    countryCode: 'TD',
  },
  'CZ+420': {
    callCode: '420',
    countryCode: 'CZ',
  },
  'CL+56': {
    callCode: '56',
    countryCode: 'CL',
  },
  'CH+41': {
    callCode: '41',
    countryCode: 'CH',
  },
  'SE+46': {
    callCode: '46',
    countryCode: 'SE',
  },
  'LK+94': {
    callCode: '94',
    countryCode: 'LK',
  },
  'EC+593': {
    callCode: '593',
    countryCode: 'EC',
  },
  'GQ+240': {
    callCode: '240',
    countryCode: 'GQ',
  },
  'ER+291': {
    callCode: '291',
    countryCode: 'ER',
  },
  'EE+372': {
    callCode: '372',
    countryCode: 'EE',
  },
  'ET+251': {
    callCode: '251',
    countryCode: 'ET',
  },
  'ZA+27': {
    callCode: '27',
    countryCode: 'ZA',
  },
  'GS+500': {
    callCode: '500',
    countryCode: 'GS',
  },
  'JM+1': {
    callCode: '1',
    countryCode: 'JM',
  },
  'ME+382': {
    callCode: '382',
    countryCode: 'ME',
  },
  'BL+590': {
    callCode: '590',
    countryCode: 'BL',
  },
  'SX+1': {
    callCode: '1',
    countryCode: 'SX',
  },
  'RS+381': {
    callCode: '381',
    countryCode: 'RS',
  },
  'AX+358': {
    callCode: '358',
    countryCode: 'AX',
  },
  'BQ+599': {
    callCode: '599',
    countryCode: 'BQ',
  },
  'GG+44': {
    callCode: '44',
    countryCode: 'GG',
  },
  'JE+44': {
    callCode: '44',
    countryCode: 'JE',
  },
  'CW+599': {
    callCode: '599',
    countryCode: 'CW',
  },
  'MF+590': {
    callCode: '590',
    countryCode: 'MF',
  },
  'SS+211': {
    callCode: '211',
    countryCode: 'SS',
  },
  'JP+81': {
    callCode: '81',
    countryCode: 'JP',
  },
  'XK+383': {
    callCode: '383',
    countryCode: 'XK',
  },
};
