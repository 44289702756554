import React, { forwardRef, useMemo } from 'react';
import { InputProps } from 'react-day-picker';

import { cn } from '../utils/cn';

export interface ThemedInputProps extends InputProps {
  label?: string;
  error?: string | boolean;
  hint?: string;
  type: 'text' | 'password' | 'email' | 'number' | 'tel';
  optional?: string;
  onValueChange?: (value: string) => void;
}

export const ThemedInput = forwardRef<HTMLInputElement, ThemedInputProps>(
  (
    { label, hint, error, type, onChange, onValueChange, optional, ...props },
    ref
  ) => {
    const uuid = useMemo(() => Math.random().toString(36).substring(7), []);

    return (
      <div className="text-left">
        <label>
          {label && (
            <p
              className={cn(
                'text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor mb-2',
                error && 'text-theme-inputs-errorColor'
              )}
            >
              {label} {optional && `(${optional})`}
            </p>
          )}
          <input
            autoComplete="off"
            type={type}
            required={!optional}
            className={cn(
              'transition-all flex w-full px-3 py-2 disabled:cursor-not-allowed',
              'text-theme-inputs-fontSize border-theme-inputs-borderColor text-theme-inputs-textColor',
              'theme-inputs-textColor bg-theme-inputs-backgroundColor [border-width:var(--inputs-borderWidth)] [border-radius:var(--inputs-cornerRadius)]',
              'placeholder-theme-inputs-placeholderColor min-h-[44px]',
              '[box-shadow:var(--inputs-shadow)] leading-normal',
              'focus-visible:border-theme-general-focusColor focus-visible:outline-1 focus-visible:outline-theme-general-focusColor outline-offset-0 outline-none',
              error &&
                'border-theme-inputs-errorColor focus-visible:border-theme-inputs-errorColor focus-visible:!outline-theme-inputs-errorColor text-theme-inputs-errorColor'
            )}
            ref={ref}
            onChange={(e) => {
              onChange?.(e);
              onValueChange?.(e.target.value);
            }}
            // Accessible error handling
            aria-invalid={!!error}
            aria-describedby={
              hint && !error
                ? uuid + 'hint'
                : error
                  ? uuid + 'error'
                  : undefined
            }
            {...props}
          />
        </label>
        {hint && (
          <p
            id={uuid + 'hint'}
            className="mt-1 text-theme-inputs-hintAndErrorFontSize text-theme-inputs-hintTextColor"
          >
            {hint}
          </p>
        )}
        {error && typeof error === 'string' && (
          <p
            id={uuid + 'error'}
            className="mt-1 text-theme-inputs-hintAndErrorFontSize text-theme-inputs-errorColor"
          >
            {error}
          </p>
        )}
      </div>
    );
  }
);

ThemedInput.displayName = 'ThemedInput';
