import { clsx, type ClassValue } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const colors = [
  'general-iconColor',
  'general-dividerColor',
  'general-focusColor',
  'page-backgroundColor',
  'canvas-backgroundColor',
  'canvas-borderColor',
  'buttons-primary-normal-backgroundColor',
  'buttons-primary-normal-textColor',
  'buttons-primary-hover-backgroundColor',
  'buttons-primary-hover-textColor',
  'typography-title-textColor',
  'typography-paragraph-textColor',
  'inputs-normal-textColor',
  'inputs-normal-borderColor',
  'inputs-errorColor',
  'inputs-placeholderColor',
  'inputs-labelTextColor',
  'inputs-hintTextColor',
  'inputs-errorTextColor',
  'inputs-checkboxesAndRadios-unchecked-backgroundColor',
  'inputs-checkboxesAndRadios-unchecked-borderColor',
  'inputs-checkboxesAndRadios-checked-backgroundColor',
  'inputs-checkboxesAndRadios-checked-borderColor',
  'inputs-checkboxesAndRadios-indicatorColor',
];

const fontSizes = [
  'typography-title-fontSize',
  'typography-paragraph-fontSize',
  'inputs-fontSize',
  'inputs-labelFontSize',
  'inputs-hintAndErrorFontSize',
];

const borderRadii = ['buttons-primary-cornerRadius', 'inputs-cornerRadius'];

const borderWidths = ['inputs-borderWidth'];

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'text-color': [{ 'text-theme': colors }],
      'bg-color': [{ 'bg-theme': colors }],
      'border-color': [{ 'border-theme': colors }],
      'font-size': [{ 'text-theme': fontSizes.map((size) => `${size}`) }],
      rounded: [{ 'rounded-theme': borderRadii.map((radius) => `${radius}`) }],
      'border-w': [{ 'border-theme': borderWidths.map((width) => `${width}`) }],
    },
  },
});

const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export { cn };
