/**
 * Format a date to a string in the format "dd MMM yyyy"
 */
export const formatDate = (date?: string | Date | null) => {
  if (!date) return undefined;

  const d = new Date(date);
  return d.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

/**
 * Format a date to a string in the format "dd MMM yyyy HH:mm"
 */
export const formatDateTime = (date?: string | Date | null) => {
  if (!date) return undefined;

  const d = new Date(date);
  return (
    formatDate(date) +
    ' ' +
    d.toLocaleTimeString('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
    })
  );
};

/**
 * Get the timezone offset in the format "GMT±hh:mm"
 * @returns The timezone offset in the format "GMT±hh:mm"
 */
export const getTimezoneOffset = () => {
  const d = new Date();
  const offset = d.getTimezoneOffset();
  const delimiter = offset < 0 ? '+' : '-';
  return `GMT${delimiter}${offset / (offset < 0 ? -60 : 60)}`;
};
