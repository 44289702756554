import {
  EvaluationContext,
  EvaluationContextValue,
  InMemoryProvider,
  OpenFeature,
  OpenFeatureProvider,
  useFlag,
} from '@openfeature/react-sdk';

export { OpenFeatureProvider as FeatureFlagProvider };

type FeatureFlagConfig = Omit<
  NonNullable<ConstructorParameters<typeof InMemoryProvider>[0]>[string],
  'contextEvaluator'
> & {
  contextEvaluator: (context: BitsFeatureFlagContext) => string;
};

export interface BitsFeatureFlagContext {
  isBitsEmployee: boolean;
  environment: 'development' | 'staging' | 'sandbox' | 'production';
  email: string;
}

export enum availableFeatureFlags {
  ENABLED_BETA_WORKFLOW_STEPS = 'ENABLED_BETA_WORKFLOW_STEPS',
  CAN_USE_TOP_LEFT_LOGO_IN_THEME = 'CAN_USE_TOP_LEFT_LOGO_IN_THEME',
  CAN_USE_ENTITY_SPIDERWEB = 'CAN_USE_ENTITY_SPIDERWEB',
  CAN_USE_RISK_SCORE = 'CAN_USE_RISK_SCORE',
}

const flagConfig: { [key in availableFeatureFlags]: FeatureFlagConfig } = {
  [availableFeatureFlags.ENABLED_BETA_WORKFLOW_STEPS]: {
    disabled: false,
    variants: {
      on: ['Kyckr Organization Information'],
      off: [],
    },
    defaultVariant: 'off',
    contextEvaluator: ({ isBitsEmployee, environment, email }) =>
      isBitsEmployee ||
      environment !== 'production' ||
      (typeof email === 'string' && email?.endsWith('continia.com')) // TODO: this is a hack to allow continia to access kyckr (2024-09-06), NB: this gives them access to all beta steps, so we want to remove this ASAP
        ? 'on'
        : 'off',
  },
  [availableFeatureFlags.CAN_USE_TOP_LEFT_LOGO_IN_THEME]: {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: 'off',
    contextEvaluator: ({ isBitsEmployee, environment }) =>
      isBitsEmployee || environment !== 'production' ? 'on' : 'off',
  },
  [availableFeatureFlags.CAN_USE_ENTITY_SPIDERWEB]: {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: 'off',
    contextEvaluator: ({ isBitsEmployee }) => (isBitsEmployee ? 'on' : 'off'),
  },
  [availableFeatureFlags.CAN_USE_RISK_SCORE]: {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: 'off',
    contextEvaluator: ({ isBitsEmployee, environment }) =>
      environment !== 'production' && isBitsEmployee ? 'on' : 'off',
  },
};

// @ts-expect-error - Hugo: Allow our strictly typed contextEvaluator
OpenFeature.setProvider(new InMemoryProvider(flagConfig));

export const setFeatureFlagContext = (
  context: Partial<BitsFeatureFlagContext>
) =>
  OpenFeature.setContext({
    ...OpenFeature.getContext(),
    ...context,
  } as EvaluationContext);

export const useFeatureFlag = useFlag;
