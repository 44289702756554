import {
  createContext,
  ElementType,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { ArrowLeft, FunnelSimple } from '@phosphor-icons/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { motion } from 'framer-motion';

import { dropdownItemClass } from '../checkbox-dropdown';
import { cn } from '../utils/cn';
import { ActiveFilterButton } from './advanced-filters-active-filter-button';
import {
  formatActiveFilterLabel,
  labelFromSavedFilter,
} from './advanced-filters-utils';
import { DateRangeFilter } from './date-range-filter';
import { OptionsFilter } from './options-filter';

const filterContext = createContext<
  Pick<AdvancedFiltersProps, 'filters' | 'activeFilters' | 'onFiltersChange'>
>({
  filters: [],
  activeFilters: {},
  onFiltersChange: () => {},
});

export const useFilterContext = () => useContext(filterContext);

export type AdvancedFilterObject = Record<
  string,
  (string | undefined | Date)[]
>;

export type AdvancedFilterGroup = {
  label: string;
  id: string;
  icon: ElementType;
} & (
  | {
      type: 'options';
      options: {
        label: ReactNode;
        value: string;
        count?: number;
      }[];
    }
  | {
      type: 'date-range';
      presets: {
        label: string;
        from?: string | Date;
        to?: string | Date;
        count?: number;
      }[];
    }
);

export interface AdvancedFiltersProps {
  /**
   * List of filters to display
   */
  filters: AdvancedFilterGroup[];
  /**
   * Active filters
   */
  activeFilters: AdvancedFilterObject;
  /**
   * Callback when filters change
   */
  onFiltersChange: (filters: AdvancedFilterObject) => void;
  /**
   * List of saved filters
   * @optional
   */
  savedFilters?: AdvancedFilterObject[];
  /**
   * Label for the trigger button
   * @optional
   */
  triggerLabel?: string;
}

/**
 * An advanced filter component that allows users to filter data based on multiple criteria.
 *
 * @example
 * ```tsx
 * <AdvancedFilters
 *   onFiltersChange={setActiveFilters}
 *   activeFilters={activeFilters}
 *   filters={[
 *    {
 *      label: 'Market',
 *      id: 'market',
 *      icon: Money,
 *      options: [
 *        { label: 'US', value: 'us' },
 *        { label: 'EU', value: 'eu' },
 *        { label: 'APAC', value: 'apac' },
 *        { label: 'LATAM', value: 'latam' },
 *      ],
 *    },
 *   ]}
 * />
 * ```
 */
export const AdvancedFilters = (props: AdvancedFiltersProps) => {
  const activeFilterArray =
    Object.entries(props.activeFilters).map(([key, values]) => ({
      key,
      values,
    })) || [];

  return (
    <filterContext.Provider value={props}>
      <section aria-label="Filters" className="flex flex-wrap gap-2">
        <FilterDropdown {...props}>
          <button
            className={cn(
              'flex items-center gap-2 px-2.5 h-[30px] bits-text-button-2 border border-fog rounded-[4px] cursor-pointer transition-colors outline-none',
              'hover:bg-ink/5 focus-within:outline-2 focus-within:outline-ink/25'
            )}
          >
            <FunnelSimple className="size-5" /> {props.triggerLabel || 'Filter'}
          </button>
        </FilterDropdown>

        {activeFilterArray.map(({ key, values }) => {
          if (!values.length) return null;
          const filter = props.filters.find((f) => f.id === key);
          if (!filter || !filter.label) return null;

          const optionValue = formatActiveFilterLabel(filter, values);

          return (
            <motion.div key={key} layout={'position'}>
              <ActiveFilterButton
                section={key}
                label={filter?.label || ''}
                values={optionValue}
                onClear={() => {
                  props.onFiltersChange({
                    ...props.activeFilters,
                    [key]: [],
                  });
                }}
              />
            </motion.div>
          );
        })}
      </section>
    </filterContext.Provider>
  );
};

interface FilterDropdownProps extends AdvancedFiltersProps {
  section?: string;
  children: ReactNode;
  backButton?: boolean;
}

export const FilterDropdown = ({
  filters,
  activeFilters,
  onFiltersChange,
  savedFilters,
  section: sectionOverride,
  children,
  backButton = true,
}: FilterDropdownProps) => {
  const [open, setOpen] = useState(false);
  const [section, setSection] = useState('');

  const close = () => {
    setOpen(false);
    setSection('');
  };

  const shownSection = sectionOverride || section;

  return (
    <DropdownMenu.Root open={open}>
      <DropdownMenu.Trigger
        onClick={() => {
          setOpen(!open);
          setSection('');
        }}
        onKeyDown={(event) => {
          if (['Enter', ' '].includes(event.key)) {
            setOpen(!open);
            setSection('');
          }
        }}
        asChild
      >
        {children}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          sideOffset={4}
          alignOffset={-8}
          className={cn(
            'animate-in fade-in-0 bg-white shadow-soft p-3 rounded-xl w-[300px] bits-text-body-2'
          )}
          onFocusOutside={close}
          onEscapeKeyDown={close}
          onCloseAutoFocus={close}
          onInteractOutside={close}
        >
          {shownSection === '' && (
            <>
              <DropdownMenu.Group className="animate-in fade-in-0 grid">
                {filters.map(({ id, icon: Icon, label, ...filter }) => (
                  <DropdownMenu.Item
                    key={id}
                    onSelect={() => setSection(id)}
                    onKeyDown={(event) => {
                      if (['ArrowRight'].includes(event.key)) {
                        setSection(id);
                      }
                    }}
                    className={dropdownItemClass}
                    disabled={
                      'options' in filter && filter.options.length === 0
                    }
                  >
                    <Icon className="size-5" /> {label}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Group>

              {/* SAVED FILTERS */}
              {savedFilters !== undefined && (
                <DropdownMenu.Group>
                  <DropdownMenu.Label className="bits-text-caption text-shadow mt-4 px-2">
                    Saved filters
                  </DropdownMenu.Label>
                  {savedFilters?.map((savedFilter, i) => (
                    <DropdownMenu.Item
                      key={i}
                      onSelect={() => {
                        onFiltersChange(savedFilter);
                        close();
                      }}
                      className={dropdownItemClass}
                    >
                      <FunnelSimple className="size-5" />
                      {labelFromSavedFilter(savedFilter, filters)}
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.Group>
              )}
            </>
          )}

          {filters.map(
            ({ label, id, type, ...filter }) =>
              shownSection === id && (
                <DropdownMenu.Group
                  key={id}
                  className="animate-in fade-in-0 grid"
                >
                  {backButton && (
                    <DropdownMenu.Item
                      aria-label="Go back"
                      className={dropdownItemClass}
                      onKeyDown={(event) => {
                        if (['ArrowLeft'].includes(event.key)) {
                          setSection('');
                        }
                      }}
                      onClick={() => setSection('')}
                    >
                      <ArrowLeft className="size-5" aria-hidden />
                      {label}
                    </DropdownMenu.Item>
                  )}

                  {type === 'options' && (
                    <OptionsFilter
                      id={id}
                      filter={filter as AdvancedFilterGroup}
                      activeFilters={activeFilters}
                      onFiltersChange={onFiltersChange}
                      setSection={setSection}
                    />
                  )}

                  {type === 'date-range' && (
                    <DateRangeFilter
                      filter={filter as AdvancedFilterGroup}
                      activeFilters={activeFilters}
                      onFiltersChange={onFiltersChange}
                      id={id}
                    />
                  )}
                </DropdownMenu.Group>
              )
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
