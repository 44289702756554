import { Fragment, ReactNode } from 'react';
import { X } from '@phosphor-icons/react';

import { cn } from '../utils/cn';
import { FilterDropdown, useFilterContext } from './advanced-filters';

interface ActiveFilterButtonProps {
  label: string;
  values: ReactNode[];
  section: string;
  onClear: () => void;
}
export const ActiveFilterButton = ({
  label,
  values,
  onClear,
  section,
}: ActiveFilterButtonProps) => {
  const filterContext = useFilterContext();

  return (
    <div className="border-fog relative flex h-[30px] items-center rounded-[4px] border bg-white">
      <FilterDropdown {...filterContext} section={section} backButton={false}>
        <button
          className={cn(
            'bits-text-button-2 hover:bg-ink/5 flex h-full cursor-pointer items-center gap-2 px-2.5 transition-colors outline-offset-2 focus-within:outline-2 focus-within:outline-ink/25'
          )}
        >
          <span className="text-ash">{label}:</span>{' '}
          {values.map((p, i) => (
            <Fragment key={i}>
              {i > 0 && <span className="text-ink -mx-1 -ml-2">,</span>}
              {p}
            </Fragment>
          ))}
        </button>
      </FilterDropdown>
      <button
        className="hover:bg-ink/5 focus-within:outline-ink/25 flex h-full w-[24px] items-center justify-center outline-offset-2 focus-within:outline-2"
        onClick={onClear}
        aria-label={'Clear filter: ' + label}
      >
        <X className="size-4" aria-hidden />
      </button>
    </div>
  );
};
