import { ISO3166Alpha2 } from '../iso-3166';
import { validateDenmarkOrgNumber } from './validate-denmark-org-number';
import { validateFinlandOrgNumber } from './validate-finland-org-number';
import { validateIrelandOrgNumber } from './validate-ireland-org-number';
import { validateNorwayOrgNumber } from './validate-norway-org-number';
import { validateSwedenOrgNumber } from './validate-sweden-org-number';
import { validateUnitedKingdomOrgNumber } from './validate-united-kingdom-org-number';

type Validator = (number: string) => boolean;

const validators: Partial<Record<ISO3166Alpha2, Validator>> = {
  SE: validateSwedenOrgNumber,
  FI: validateFinlandOrgNumber,
  NO: validateNorwayOrgNumber,
  DK: validateDenmarkOrgNumber,
  IE: validateIrelandOrgNumber,
  GB: validateUnitedKingdomOrgNumber,
} as const;

/**
 * Validates organization number based on issuing country
 *
 * @param number
 * @param issuingCountry
 * @returns Boolean
 */
export const validateOrgNumber = (
  number: string,
  issuingCountry?: ISO3166Alpha2
) => {
  if (!number || !issuingCountry) {
    return false;
  }

  const validator = validators[issuingCountry];

  if (validator) {
    return validator(number);
  }

  return true;
};
